/** Digits 0-9 */
const DIGITS = [
    'zéro',
    'un',
    'deux',
    'trois',
    'quatre',
    'cinq',
    'six',
    'sept',
    'huit',
    'neuf',
];
/** Tens 10-19 */
const TENS = [
    'dix',
    'onze',
    'douze',
    'treize',
    'quatorze',
    'quinze',
    'seize',
    'dix-sept',
    'dix-huit',
    'dix-neuf',
];
/** Words for the number in the tens place 10-90 */
const TEN_WORDS = [
    'dix',
    'vingt',
    'trente',
    'quarante',
    'cinquante',
    'soixante',
    'soixante-dix',
    'quatre-vingt',
    'quatre-vingt-dix',
];
/**
 * Words for order of magnitude starting at 1000 (mille).
 * This could be infinitely expanded
 */
const BIG_WORDS = [
    'mille',
    'million',
    'milliard',
    'billion',
    'billiard',
    'trillion',
    'trilliard',
    'quadrillion',
    'quadrilliard',
    'quintillion',
    'quintrilliard',
    'sextillion',
    'sextilliard',
    'septillion',
    'septilliard',
    'octillion',
    'octilliard',
    'nonillion',
    'nonilliard',
    'décillion',
    'décilliard',
];
/** Convert one or more NumberSegments to words */
function segmentToWords(...segments) {
    let result = '';
    let addHyphen = false;
    const add = (str) => {
        if (!str)
            return;
        if (addHyphen)
            result += '-';
        else
            addHyphen = true;
        result += str;
    };
    for (const word of segments) {
        add(word.hundredsWord);
        add(word.tensOnesWord);
        add(word.bigWord);
    }
    return result;
}
function createWords(tenWord) {
    const words = [tenWord];
    let weirdOne = false;
    if (tenWord.endsWith('-dix')) {
        tenWord = tenWord.replace('-dix', '');
        weirdOne = true;
    }
    for (const [i, d] of DIGITS.entries()) {
        if (i === 0)
            continue;
        if (weirdOne) {
            if (i === 1)
                words.push(`${tenWord}-et-${TENS[i]}`);
            else
                words.push(`${tenWord}-${TENS[i]}`);
            continue;
        }
        // Digit is 1 and number is not 81
        if (i === 1 && tenWord !== TEN_WORDS[7]) {
            words.push(`${tenWord}-et-${d}`);
        }
        else {
            words.push(`${tenWord}-${d}`);
        }
    }
    return words;
}
/**
 * Split a number into an array of groups of three.
 * The first array will be of length 1 or 2 of it can't fit into 3
 * @param num The number to split
 * @returns The split number. The first element may be of length 1, 2, or 3,
 * but all following elements will always be of length 3
 */
function groupThrees(num) {
    const split = num.toString().split('');
    const groups = [];
    const maxIndex = split.length - 1;
    const firstGroup = split.length % 3 === 0 ? 3 : (split.length + 1) % 3 === 0 ? 2 : 1;
    let digitsToSkip = 0;
    let iOffset = 1;
    for (let i = 0; i < split.length; i++) {
        // If the first group if digits is not of length three,
        // and we've reached the end of the first group
        if (firstGroup !== 3 && i + 1 === firstGroup) {
            // We have two digits in this group. Add them and skip the next digit
            if (firstGroup === 2) {
                groups.push([split[i - 1], split[i]]);
                digitsToSkip = 1;
                iOffset += 1;
                // We have one digit in this group. Add it and skip the next two digits
            }
            else {
                groups.push([split[i]]);
                digitsToSkip = 2;
                iOffset += 2;
            }
            continue;
        }
        // If there are no digits to skip and we're at the third of this group,
        // or we've reached the last digit in the number
        if ((!digitsToSkip && (i + iOffset) % 3 === 0) || i === maxIndex) {
            groups.push([split[i - 2], split[i - 1], split[i]]);
        }
        // Decrement digits to skip each loop
        if (digitsToSkip > 0)
            digitsToSkip--;
    }
    return groups;
}
/** Get the word for the last two digits (eg. "vingt-deux" for 22) */
function getTenWord(tens, ones, bigWord) {
    const tenWord = tens > 1
        ? createWords(TEN_WORDS[tens - 1])[ones]
        : tens === 1
            ? TENS[ones]
            : ones !== 0
                ? DIGITS[ones]
                : undefined;
    return !bigWord && tenWord === TEN_WORDS[7] ? `${tenWord}s` : tenWord;
}
/** Get whether to make a "big word" plural */
const getBigWordPlural = (bigWord, ones, tens, hundreds) => bigWord && ((hundreds && hundreds > 0) || (tens && tens > 0) || ones > 1);
/**
 * Convert a number into the French writing of it
 * @param num The number to convert
 * @returns A string of the word representation of the number
 */
export default function convert(num) {
    /** The number grouped into groups of three */
    const grouped = groupThrees(num)
        .map(group => group.map(num => parseInt(num)))
        .reverse();
    const words = [];
    for (const [i, group] of grouped.entries()) {
        // If the group is all zeroes
        /** "Big word" for the number (eg. "mille" or "milliard") */
        const bigWord = i > 0 ? BIG_WORDS[i - 1] : undefined;
        if (group.every(x => x === 0))
            continue;
        if (group.length === 3) {
            const bigWordPlural = getBigWordPlural(bigWord, group[2], group[1], group[0]);
            const tenWord = getTenWord(group[1], group[2], bigWord);
            const hundred = group[0] > 0 ? 'cent' : undefined;
            const hundredPlural = group[0] > 0 && !tenWord && !bigWord ? 's' : '';
            /** Prefix for hundred (eg. "quatre" for 400 or nothing for 100) */
            const hundredPrefix = group[0] > 1 ? DIGITS[group[0]] : undefined;
            words.push({
                originalDigits: group,
                hundredsWord: hundred
                    ? hundredPrefix
                        ? `${hundredPrefix}-${hundred}${hundredPlural}`
                        : hundred
                    : undefined,
                tensOnesWord: tenWord,
                bigWord: bigWord
                    ? `${bigWord}${bigWordPlural ? 's' : ''}`
                    : undefined,
            });
        }
        else if (group.length === 2) {
            const bigWordPlural = getBigWordPlural(bigWord, group[1], group[0]);
            const tenWord = getTenWord(group[0], group[1], bigWord);
            words.push({
                originalDigits: group,
                tensOnesWord: tenWord,
                bigWord: bigWord
                    ? `${bigWord}${bigWordPlural ? 's' : ''}`
                    : undefined,
            });
        }
        else {
            const bigWordPlural = getBigWordPlural(bigWord, group[0]);
            words.push({
                originalDigits: group,
                tensOnesWord: bigWord === BIG_WORDS[0] && group[0] == 1
                    ? undefined
                    : DIGITS[group[0]],
                bigWord: bigWord
                    ? `${bigWord}${bigWordPlural ? 's' : ''}`
                    : undefined,
            });
        }
    }
    return segmentToWords(...words.reverse());
}
