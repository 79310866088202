<script lang="ts">
  import convert from './convert'

  /** The user's number, bound to the input */
  let number = ''
  /** The number converted to words */
  let converted: string

  // Automatically updated converted when the number is changed
  $: {
    const numberStripped = number.replaceAll(/[\s,]+/g, '')

    if (numberStripped.length === 0) {
      converted = 'Enter a number'
    } else {
      if (numberStripped.search(/[^\d]+/g) >= 0)
        converted = 'Invalid number provided'
      else converted = convert(numberStripped)
    }
  }
</script>

<main>
  <div class="container">
    <h1>Spell French Numbers</h1>
    <p>Convert a number to its French spelling</p>
    <input
      type="text"
      bind:value={number}
      placeholder="Enter a number, such as 1234 or 123 456"
    />
    <h3>{converted}</h3>
  </div>
</main>

<style>
  main {
    width: 100%;
    height: 100%;
    margin: 0;
  }

  @media (min-width: 900px) {
    main {
      display: flex;
      justify-content: center;
      text-align: center;
    }

    .container {
      width: 50%;
    }
  }

  .container {
    justify-content: center;
    text-align: center;
    margin: auto;
  }

  h1,
  h3 {
    color: #ff3e00;
    text-transform: uppercase;
    font-weight: 100;
  }

  h1 {
    font-size: 4em;
  }

  h3 {
    font-size: 2em;
  }
</style>
